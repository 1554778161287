<template>
  <div>
    <!-- Page Heading -->
    <v-toolbar flat>
      <pageHeading pageTitle="Store Order Report" />
      <v-spacer></v-spacer>
      <!-- <v-btn class="mx-2" depressed dark color="primary" @click="()=>$router.push('/approve_drivers/edit')">
          <v-icon left dark>mdi-plus-circle</v-icon> Add
        </v-btn> -->
    </v-toolbar>
    <v-divider></v-divider>
    <div class="content-wrap">
      <v-toolbar class="search-tools pa-0 mb-10 mt-5" flat>
        <!-- Action Button -->
        <!-- <v-spacer></v-spacer> -->
        <v-row>
          <v-col class="d-flex pl-0" cols="2" sm="2">
            <v-text-field
              label="Trip No"
              v-model="options.filter.trip_name"
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
              clearable
              @keyup.native.enter="getDataFromApi"
            />
          </v-col>
          <v-col class="d-flex pl-0" cols="2" sm="2">
            <v-text-field
              label="Order No"
              v-model="options.filter.order_no"
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
              clearable
              @keyup.native.enter="getDataFromApi"
            />
          </v-col>
          <v-col class="d-flex pl-0" cols="2" sm="2">
            <v-text-field
              label="Driver No"
              v-model="options.filter.employee_no"
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
              clearable
              @keyup.native.enter="getDataFromApi"
            />
          </v-col>
          <v-col class="d-flex pl-0" cols="2" sm="2">
            <v-select
              :items="trip_cases"
              label="Case"
              v-model="options.filter.case"
              no-data-text="Select.."
              item-text="text"
              item-value="value"
              clearable
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex pl-0" cols="2" sm="2">
            <v-autocomplete
              label="Order Store"
              v-model="options.filter.order_store_uid"
              :items="rb_store_array_filtered"
              item-text="store_alias_id"
              item-value="store_uid"
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
            />
          </v-col>
          <v-col class="d-flex px-0" cols="2" sm="2">
            <v-autocomplete
              label="Rider Store"
              v-model="options.filter.employee_store_uid"
              :items="rb_store_array"
              item-text="store_alias_id"
              item-value="store_uid"
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
            />
          </v-col>
          <v-col class="d-flex px-0" cols="3" sm="3">
            <v-select
              v-model="options.filter.ln_status"
              :items="lnHookStatus"
              item-text="value"
              item-value="value"
              label="Order Status"
              multiple
              :single-line="true"
              height="32px"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle_multi_select">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        options.filter.ln_status.length > 0
                          ? 'indigo darken-4'
                          : ''
                      "
                      >{{ selectall_icon }}</v-icon
                    >
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2">
            <v-select
              :items="op_fleet_name"
              label="Fleet"
              v-model="options.filter.fleet_name"
              no-data-text="Select.."
              item-text="text"
              item-value="value"
              clearable
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="3" sm="3">
            <v-menu
              v-model="filterDatePicker"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="filter_date_formatted"
                  label="Transaction Date"
                  readonly
                  v-on="on"
                  @click:clear="clearFilterDaterange()"
                  hide-details
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.filter.date_range"
                @change="filterDatePicker = false"
                range
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2" v-if="user_type == 'REDBOOK'">
            <v-select
              v-model="options.filter.scope_store_rider"
              :items="scope_store_rider"
              item-text="txt"
              item-value="val"
              label="Scope"
              :single-line="true"
              height="32px"
            >
            </v-select>
          </v-col>
          <!-- <v-spacer></v-spacer> -->
          <v-col cols="2" sm="2">
            <v-btn
              class="mb-1 mx-2"
              depressed
              dark
              small
              color="primary"
              @click="
                options.page = 1;
                getTripsReport(options);
              "
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              class="mb-1 mx-2"
              depressed
              dark
              small
              color="primary"
              @click="exportTripsReport(options)"
            >
              <v-icon>mdi-export</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="dataLisings"
        item-key="id"
        :single-expand="true"
        :options.sync="options"
        :server-items-length="totalResult"
        :loading="loadingDataTable"
        :expanded.sync="expanded"
        multi-sort
        :footer-props="{
          'items-per-page-options': [25, 100, 500],
        }"
        class="text-small"
        :show-expand="false"
        @click:row="handleClickRow"
        @item-expanded="handleExpandItem"
      >
        <template #item.order_no="{item}">
          <v-chip
            color="#BDFABD"
            small
            v-if="
              item.employee_tier_id == tpc_tier_id &&
                item.store_uid != item.employee_store_uid &&
                user_type == 'REDBOOK'
            "
            label
          >
            {{ item.order_no }}
          </v-chip>
          <v-chip
            color="#FFFFFF"
            small
            v-if="
              item.employee_tier_id == tpc_tier_id &&
                item.store_uid == item.employee_store_uid &&
                user_type == 'REDBOOK'
            "
            label
          >
            {{ item.order_no }}
          </v-chip>
          <v-chip
            color="#EBBEFD"
            small
            v-if="
              item.employee_tier_id != tpc_tier_id && user_type == 'REDBOOK'
            "
            label
          >
            {{ item.order_no }}
          </v-chip>
          <span v-if="user_type == 'DM'">{{ item.order_no }}</span>
        </template>

        <template #item.employee_name="{item}">
          <router-link
            v-if="item.employee_id"
            :to="{ name: 'DriversDetails', params: { id: item.employee_id } }"
            >{{ item.employee_name }}</router-link
          ><br />
          {{ item.employee_no }}
        </template>
        <template #item.distance="{value}">
          {{ value | comma }}
        </template>
        <template #item.total_amount="{value}">
          {{ value | comma }}
        </template>
        <template #item.order_created_at="{value}">
          {{ value | datetime }}
        </template>
        <template #item.webhook_updated_at="{value}">
          <span v-if="value">{{ value | datetime }}</span>
        </template>
        <template #item.action="{item}">
          <v-btn class="ma-1" outlined color="primary" icon tile small>
            <v-icon small @click="manualOrderTopup(item.order_no)"
              >mdi-wallet-plus</v-icon
            >
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row class="expand-info">
              <v-col cols="3" sm="3">
                <h3 class="mb-1">Order Details</h3>
                <div>
                  <span class="info-title">Channel</span
                  ><span class="info-detail">{{ item.channel }}</span>
                </div>
                <div>
                  <span class="info-title">Gross Amount (bht)</span
                  ><span class="info-detail">{{
                    item.gross_amount | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Discount Amount (bht)</span
                  ><span class="info-detail">{{
                    item.discount_amount | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Voucher Amount (bht)</span
                  ><span class="info-detail">{{
                    item.voucher_deduct_food || 0
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Voucher Type</span
                  ><span class="info-detail">{{
                    item.voucher_type || '-'
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Delivery Fee (bht)</span
                  ><span class="info-detail">{{
                    item.delivery_fee | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Total Amount (bht)</span
                  ><span class="info-detail">{{
                    item.total_amount | comma
                  }}</span>
                </div>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                v-if="item.fleet_name != 'PANDAGO' && item.fleet_name != 'GRAB'"
              >
                <h3 class="mb-1">Driver Payment</h3>
                <div>
                  <span class="info-title">Driver Distance (m)</span
                  ><span class="info-detail">{{ item.distance | comma }}</span>
                </div>
                <div>
                  <span class="info-title">Driver Delivery Fee (bht)</span
                  ><span class="info-detail">{{
                    item.driver_delivery_fee | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Driver Getpaid Food (bht)</span
                  ><span class="info-detail">{{
                    item.driver_getpaid_food | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Driver Getpaid Delivery (bht)</span
                  ><span class="info-detail">{{
                    item.driver_getpaid_delivery | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Commission (bht)</span
                  ><span class="info-detail">{{
                    item.commission | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Topup Food (bht)</span
                  ><span class="info-detail">{{
                    item.topup_food | comma
                  }}</span>
                </div>
                <div>
                  <span class="info-title">Topup Delivery (bht)</span
                  ><span class="info-detail">{{
                    item.topup_delivery | comma
                  }}</span>
                </div>
              </v-col>
              <v-col cols="6" sm="6">
                <!-- Store -->
                <template
                  v-if="
                    item.employee_store_uid &&
                      rb_stores &&
                      rb_stores[item.employee_store_uid]
                  "
                >
                  <h3 class="">
                    Rider Store ::
                    {{ rb_stores[item.employee_store_uid].store_alias_id }}
                    {{ rb_stores[item.employee_store_uid].name_th }}
                  </h3>
                </template>
                <template
                  v-if="
                    item.store_uid && rb_stores && rb_stores[item.store_uid]
                  "
                >
                  <h3 class="">
                    Order Store ::
                    {{ rb_stores[item.store_uid].store_alias_id }}
                  </h3>
                  <div v-if="rb_stores && rb_stores[item.store_uid]">
                    {{ rb_stores[item.store_uid].name_th }}, Manage :
                    {{ rb_stores[item.store_uid].phone_manager }}, Store :
                    {{ rb_stores[item.store_uid].phone_store }}
                  </div>
                  <div v-if="rb_stores && rb_stores[item.store_uid]">
                    {{ rb_stores[item.store_uid].delivery_zone }}
                  </div>

                  <v-col
                    v-if="
                      current_expanded_item.is_re_dispatch &&
                        !isForceShowManualAssign &&
                        isEnableChangeAssignRider
                    "
                    class="d-flex pl-0 pb-0"
                  >
                    <v-btn
                      color="primary"
                      small
                      @click="handleReDispatchRider(item)"
                    >
                      Change Rider
                    </v-btn>
                  </v-col>

                  <v-col
                    v-if="
                      (current_expanded_item.is_able_manual_assign ||
                        isForceShowManualAssign) &&
                        isEnableManualAssignRider
                    "
                    class="d-flex pl-0 pb-0"
                    cols="7"
                    sm="7"
                  >
                    <v-text-field
                      ref="rider_mobile"
                      type="tel"
                      :label="getAssignRiderLabel(item)"
                      v-model="assign_rider_mobile_no"
                      v-mask="'###-###-####'"
                      clearable
                      editable
                      @keyup.native.enter="checkManualAssignRider()"
                    />
                    <v-btn
                      class="mt-5 ml-2"
                      color="primary"
                      :loading="isCheckRiderPhoneLoading"
                      :disabled="isCheckRiderPhoneLoading"
                      small
                      @click="checkManualAssignRider()"
                    >
                      <v-icon>mdi-magnify</v-icon>
                      Find</v-btn
                    >
                  </v-col>
                  <v-col
                    v-if="searchRiderInfo && !searchRiderInfo.error"
                    class="d-flex pl-0 pt-0"
                    cols="7"
                    sm="7"
                  >
                    <v-text-field
                      v-model="assign_rider_name"
                      disabled
                      readonly
                    />
                    <v-btn
                      class="mt-5 ml-2"
                      color="primary"
                      :loading="isAssignRiderLoading"
                      small
                      @click="checkManualAssignRider('Assign', item)"
                      >Assign</v-btn
                    >
                  </v-col>
                </template>
                <!-- Transaction -->
                <template v-if="item.transaction.length > 0">
                  <h3 class="mb-2">Transactions</h3>
                  <v-simple-table class="custom-table1" width="600">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left py-1" width="200">
                            Transaction Type
                          </th>
                          <th class="text-center" width="100">Amount(Bht)</th>
                          <th class="text-center" width="100">Status</th>
                          <th class="text-left" width="200">Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="t in item.transaction" :key="t.id">
                          <td>{{ t.transaction_type }}</td>
                          <td class="text-center">{{ t.amount }}</td>
                          <td class="text-center">
                            <v-chip
                              v-if="t.tmn_transfer_status == 1"
                              color="green"
                              dark
                              x-small
                              >Success</v-chip
                            >
                            <v-chip
                              v-else-if="t.tmn_transfer_status == -1"
                              color="red darken-4"
                              dark
                              x-small
                              >Fail Verify</v-chip
                            >
                            <v-chip
                              v-else-if="t.tmn_transfer_status == -2"
                              color="red darken-4"
                              dark
                              x-small
                              >Fail Notify</v-chip
                            >
                          </td>
                          <td>{{ t.updatedAt | datetime }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div style="height: 15px; display:block;"></div>
                </template>
                <template v-if="item.tickets && item.tickets.length > 0">
                  <h3 class="mb-2">Manual Topup Tickets</h3>
                  <v-simple-table class="custom-table1" width="600">
                    <tbody>
                      <tr v-for="tk in item.tickets" :key="tk.id">
                        <td width="200" class="text-left">
                          <span>{{
                            tk.ticket_data
                              ? tk.ticket_data.topup_type
                              : tk.ticket_type
                          }}</span>
                          <span v-if="tk.ticket_id && !tk.ticket_link"
                            >[ #{{ tk.ticket_id }} ]</span
                          >
                          <a
                            :href="tk.ticket_link"
                            target="blank"
                            v-if="tk.ticket_id && tk.ticket_link"
                            >[ #{{ tk.ticket_id }} ]</a
                          >
                        </td>
                        <td width="100" class="text-center">
                          {{
                            tk.ticket_data
                              ? tk.ticket_data.topup_amount + ' Bht'
                              : ''
                          }}
                        </td>
                        <td width="100" class="text-center">
                          <v-chip
                            v-if="tk.is_approve == true"
                            color="green"
                            dark
                            x-small
                            >Approved</v-chip
                          >
                          <v-chip
                            v-else-if="tk.is_approve == false"
                            color="red darken-4"
                            dark
                            x-small
                            >Rejected</v-chip
                          >
                          <v-chip v-else color="grey lighten-2" dark x-small
                            >Pending</v-chip
                          >
                        </td>
                        <td width="200" class="text-left">
                          {{ tk.updatedAt | datetime }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>

      <!-- Manual Topup Modal -->
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Manual Topup</span>
          </v-card-title>
          <v-card-text>
            <v-form v-model="form_valid" ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.order_no"
                      label="Order No"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.amount"
                      :rules="[validate_rules.required]"
                      type="number"
                      label="Amount"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      :items="orderTopupTransactionTypes"
                      label="Transaction type"
                      v-model="editedItem.transaction_type"
                      :rules="[validate_rules.required]"
                      no-data-text="Select.."
                      item-text="value"
                      item-value="value"
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="Note"
                      v-model="editedItem.note"
                      rows="3"
                      no-resize
                      clearable
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="SMS Message"
                      v-model="editedItem.sms"
                      rows="3"
                      no-resize
                      clearable
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="preview">Submit</v-btn>
          </v-card-actions>
        </v-card>
        <!-- End Modal Content -->
      </v-dialog>

      <!-- Preview Dialog -->
      <v-dialog v-model="preview_dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Are you sure to manual topup for:</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <strong>Order No:</strong> {{ editedItem.order_no }}
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <strong>Amount:</strong> {{ editedItem.amount }} Bht
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <strong>Transaction type:</strong>
                  {{ editedItem.transaction_type }}
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <strong>Note:</strong> {{ editedItem.note }}
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="editedItem.sms">
                  <strong>SMS Message:</strong> {{ editedItem.sms }}
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="preview_close"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="save">Confirm</v-btn>
          </v-card-actions>
        </v-card>
        <!-- End Modal Content -->
      </v-dialog>

      <!-- Manual Rider Assign Modal -->
      <v-dialog
        v-model="manual_assign_rider_dialog"
        :persistent="true"
        max-width="512px"
      >
        <v-card>
          <v-card-title
            v-if="
              riderAvailability &&
                !assignRiderResponse &&
                riderAvailability.is_warning
            "
            class="d-flex flex-column align-center"
          >
            <v-icon color="info" size="44">mdi-alert-circle</v-icon>
            <span class="headline text-left">Rider already assigned</span>
          </v-card-title>

          <v-card-title
            v-if="assignRiderResponse && assignRiderResponse.success"
            class="d-flex flex-column align-center"
          >
            <v-icon color="success" size="44">mdi-check-circle</v-icon>
            <span class="headline text-left">Rider assign success</span>
          </v-card-title>

          <v-card-title
            v-if="assignRiderResponse && !assignRiderResponse.success"
            class="d-flex flex-column align-center"
          >
            <v-icon color="error" size="44">mdi-alert</v-icon>
            <span class="headline text-left">Rider assign error</span>
          </v-card-title>

          <v-card-title
            v-if="searchRiderInfo && searchRiderInfo.error"
            class="d-flex flex-column align-center"
          >
            <v-icon color="error" size="44">mdi-alert</v-icon>
            <span class="headline text-left"
              >Rider mobile phone check error</span
            >
          </v-card-title>

          <v-card-text
            v-if="
              riderAvailability &&
                !assignRiderResponse &&
                riderAvailability.is_warning
            "
            class="px-10 pb-0"
          >
            คนขับรับงานอยู่แล้ว {{ riderAvailability.current_trip_count }} งาน
            หากต้องการจ่ายงานให้คนขับเบอร์ {{ assign_rider_mobile_no }} กด
            Submit เปลี่ยนคนขับใหม่ กด Edit
          </v-card-text>

          <v-card-text
            v-if="assignRiderResponse && assignRiderResponse.success"
            class="text-center pb-0"
          >
            จ่ายงานให้คนขับสำเร็จ
          </v-card-text>

          <div v-if="assignRiderResponse && !assignRiderResponse.success">
            <v-card-text class="text-center">
              ไม่สามารถจ่ายงานให้คนขับได้
              กรุณาตรวจสอบข้อมูลและทำรายการใหม่อีกครั้ง
            </v-card-text>
            <v-card-text class="text-sm-center pb-0">
              status: {{ assignRiderResponse.error.code }} | zeek_status:
              {{ assignRiderResponse.error.zeek_code }} <br />
              {{ assignRiderResponse.error.message }}
            </v-card-text>
          </div>

          <div v-if="searchRiderInfo && searchRiderInfo.error">
            <v-card-text class="text-center pb-0">
              status: {{ searchRiderInfo.error.status }} <br />
              {{ handleCheckErrorMessage(searchRiderInfo.error) }}
            </v-card-text>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="assignRiderResponse && assignRiderResponse.success"
              color="blue darken-1"
              text
              :loading="isDelayDoneManualAssign"
              @click="checkManualAssignRider('Done')"
              >Done
            </v-btn>
            <v-btn
              v-if="assignRiderResponse && !assignRiderResponse.success"
              color="blue darken-1"
              text
              @click="checkManualAssignRider('Edit')"
              >Close
            </v-btn>
            <v-btn
              v-if="
                riderAvailability &&
                  !assignRiderResponse &&
                  riderAvailability.is_warning
              "
              color="blue darken-1"
              text
              :disabled="isAssignRiderLoading"
              @click="checkManualAssignRider('Edit')"
              >Edit
            </v-btn>
            <v-btn
              v-if="
                riderAvailability &&
                  !assignRiderResponse &&
                  riderAvailability.is_warning
              "
              color="blue darken-1"
              text
              @click="checkManualAssignRider('Confirm')"
              >Submit
            </v-btn>
            <v-btn
              v-if="searchRiderInfo && searchRiderInfo.error"
              color="blue darken-1"
              text
              @click="checkManualAssignRider('Done')"
              >OK
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- End Modal Content -->
      </v-dialog>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';

export default {
  name: 'TripsReport',
  components: {
    pageHeading,
  },
  data: () => ({
    tpc_tier_id: null,
    scope_store_rider: [
      { txt: 'All Rider', val: null },
      { txt: 'Only My Store Rider', val: 'same' },
      { txt: 'Except My Store Rider', val: 'other' },
    ],
    op_fleet_name: [
      { value: 'LOGINEXT', text: 'Loginext' },
      { value: 'ZEEK', text: 'Zeek' },
      { value: 'GRAB', text: 'Grab' },
      { value: 'PANDAGO', text: 'Panda Go' },
    ],
    user_type: null,
    rb_stores: null,
    rb_store_array: [],
    rb_store_array_filtered: [],
    dialog: false,
    preview_dialog: false,
    filterDatePicker: false,
    assign_rider_mobile_no: '',
    assign_rider_name: '',
    current_assign_item: null,
    manual_assign_rider_dialog: false,
    manual_assign_store_list: [],
    isForceShowManualAssign: false,
    isDelayDoneManualAssign: false,
    isEnableManualAssignRider: false,
    isEnableChangeAssignRider: false,
    current_expanded_item: {
      is_re_dispatch: false,
      is_able_manual_assign: false,
    },
    editedItem: {
      order_no: '',
      amount: 0,
      transaction_type: null,
      note: '',
      sms: '',
    },
    defaultItem: {
      order_no: '',
      amount: 0,
      transaction_type: null,
      note: '',
      sms: '',
    },
    options: {
      page: 1,
      itemsPerPage: 25,
      sortBy: ['order_created_at', 'order_no'],
      sortDesc: [true, false],
      multiSort: true,
      mustSort: true,
      filter: {
        trip_name: '',
        order_no: '',
        employee_no: '',
        ln_status: [],
        date_range: [],
        case: null,
        scope_store_rider: null,
        order_store_uid: null,
        employee_store_uid: null,
        scope_store_type: 'Order',
      },
    },
    expanded: [],
    isSingleExpand: false,
    trip_cases: [{ value: 'ln_manual', text: 'LN Manual Order' }],
    form_valid: false,
    validate_rules: {
      required: (v) => !!v || 'This field is required',
      select2: (v) => {
        if (typeof v === 'object') {
          return v.value !== '' || 'This field is required';
        } else {
          return true;
        }
      },
      // select: v => { return (v !== '') || 'This field is required' },
      number: (v) => /^[0-9]/.test(v) || 'Number only please',
    },
  }),
  watch: {
    'options.sortBy': function(newVal, oldVal) {
      //to work with changes in someOtherProp
      if (newVal) {
        this.getTripsReport(this.options);
      }
    },
    dialog(val) {
      val || this.close();
    },
    filterDatePicker(val) {
      if (val && this.options.filter.date_range.length == 0) {
        this.options.filter.date_range = [
          moment()
            .subtract(0, 'days')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ];
      }
    },
  },
  filters: {
    trans_status(value) {
      return value == '0' ? 'success' : value;
    },
  },
  methods: {
    ...mapActions([
      'genCan',
      'getTripsReport',
      'sendOrderManualTopup',
      'getAllRedbookStore',
      'getTPCTierId',
      'exportTripsReport',
      'getRiderTaskInfo',
      'riderAssignPartner',
      'getCheckRiderWithPhoneNumber',
      'getCheckRiderAvailability',
      'assignRider',
      'resetRiderManualAssign',
    ]),

    prepareStoreData: async function() {
      const lodash = require('lodash');
      console.log('Load redbook store');
      this.rb_store_array = await this.getAllRedbookStore({ load_full: true });
      this.rb_store_array_filtered = await this.getAllRedbookStore();

      this.rb_stores = lodash.keyBy(this.rb_store_array, 'store_uid');
    },
    getDataFromApi() {
      this.options.page = 1;
      this.getTripsReport(this.options);
    },
    clearFilterDaterange() {
      this.options.filter.date_range = [];
    },
    toggle_multi_select() {
      this.$nextTick(() => {
        if (this.selectedAll) {
          this.options.filter.ln_status = [];
        } else {
          this.options.filter.ln_status = this.lnHookStatus.slice();
        }
      });
    },
    manualOrderTopup(order_no) {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.order_no = order_no;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    preview() {
      if (this.form_valid) {
        this.dialog = false;
        this.preview_dialog = true;
      }
    },
    preview_close() {
      this.preview_dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.$refs.form.resetValidation(); // prevent validate error show again when click add
      }, 300);
    },
    save() {
      this.sendOrderManualTopup(this.editedItem);
      this.preview_close();
    },
    getAssignRiderLabel(item) {
      return this.current_expanded_item.is_re_dispatch
        ? 'Re-Dispatch Rider Mobile No.'
        : 'Assign Rider Mobile No.';
    },
    async checkManualAssignRider(action = '', currentItem = null) {
      switch (action) {
        case 'Cancel':
          this.assign_rider_mobile_no = '';
          this.manual_assign_rider_dialog = false;
          await this.resetRiderManualAssign();
          break;
        case 'Edit':
          this.manual_assign_rider_dialog = false;
          await this.resetRiderManualAssign();
          this.$refs?.rider_mobile?.$el
            .querySelector('.v-input__slot input')
            ?.focus();
          break;
        case 'Assign': {
          this.current_assign_item = currentItem;
          if (this.riderAvailability && this.riderAvailability.is_warning) {
            this.manual_assign_rider_dialog = true;
          } else if (
            this.riderAvailability &&
            !this.riderAvailability.is_warning
          ) {
            this.manual_assign_rider_dialog = false;
            this.checkManualAssignRider('Confirm');
          } else {
            const Toast = this.$swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: 'error',
              title: 'Not found rider availability info please try again',
            });
          }

          break;
        }
        case 'Confirm': {
          let user_code;
          try {
            const order_no_parts = this.current_assign_item.order_no.split('_');
            if (!order_no_parts[1]) {
              throw new Error('Invalid order number format');
            }
            user_code = order_no_parts[1].slice(-4);
          } catch (error) {
            console.error('Error extracting user code:', error);
            const Toast = this.$swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: 'error',
              title: 'Error processing order number',
            });
            return;
          }

          const assign_payload = {
            client_merchant_id: this.current_assign_item.store_alias_id,
            partner_phone: this.searchRiderInfo.phone,
            user_code: user_code,
            webhook_order_id: this.current_assign_item.webhook_order_id,
            order_no: this.current_assign_item.order_no,
          };

          await this.assignRider(assign_payload);
          this.manual_assign_rider_dialog = true;

          this.isDelayDoneManualAssign = true;
          setTimeout(() => {
            this.isDelayDoneManualAssign = false;
          }, 3000);
          break;
        }
        case 'Done': {
          this.manual_assign_rider_dialog = false;
          this.assign_rider_mobile_no = '';
          this.assign_rider_name = '';
          await this.resetRiderManualAssign();

          this.initDatetimeWithAutoSet();
          break;
        }

        default: {
          if (this.assign_rider_mobile_no) {
            const mobileNo = this.assign_rider_mobile_no.replace(/-/g, '');

            await this.getCheckRiderWithPhoneNumber(mobileNo);
            if (this.searchRiderInfo && !this.searchRiderInfo.error) {
              this.assign_rider_name = `${this.searchRiderInfo.name_th ||
                '-'} ${this.searchRiderInfo.employee_no || '-'}`;

              await this.getCheckRiderAvailability(
                this.searchRiderInfo.employee_no
              );
            } else {
              this.manual_assign_rider_dialog = true;
            }
          } else {
            const Toast = this.$swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            Toast.fire({
              icon: 'error',
              title: 'Please enter rider mobile number',
            });
          }

          break;
        }
      }
    },

    async getManualAssignStoreList() {
      try {
        const manualAssignStoreList = JSON.parse(
          process.env.VUE_APP_MANUAL_ASSIGN_STORE_LIST
        );
        this.manual_assign_store_list = manualAssignStoreList;
      } catch (error) {
        console.error('Error parsing manual assign store list:', error);
        this.manual_assign_store_list = [];
      }
    },

    handleClickRow(event) {
      if (this.expanded.length > 0) {
        const current = this.expanded.find((item) => item.id === event.id);
        if (current) {
          this.expanded = [];
        } else {
          this.expanded = [event];
          this.handleExpandItem(event);
        }
      } else {
        this.expanded = [event];
        this.handleExpandItem(event);
      }
    },

    handleExpandItem(event) {
      this.resetRiderManualAssign();
      this.assign_rider_mobile_no = '';
      this.isForceShowManualAssign = false;
      this.isEnableManualAssignRider = false;
      this.isEnableChangeAssignRider = false;

      if (!event) {
        return;
      }

      this.current_expanded_item = event;

      const reDispatchStatuses = [
        'ORDER ACCEPTED',
        'UPDATED',
        'NEARBY_STORE',
        'PICKEDUPNOTIFICATION',
        'NEARBY_CUSTOMER',
      ];

      const isDM = this.user_type === 'DM';
      const currentStatus = this.current_expanded_item.latest_ln_order_status;
      const storeId = this.current_expanded_item.store_alias_id;

      // Set re-dispatch flag
      this.current_expanded_item.is_re_dispatch = isDM
        ? reDispatchStatuses.includes(currentStatus)
        : reDispatchStatuses.includes(currentStatus) &&
          this.manual_assign_store_list.includes(storeId);

      // Set manual assign flag
      const isOrderCreation = currentStatus === 'ORDERCREATIONNOTIFICATION';
      this.current_expanded_item.is_able_manual_assign = isDM
        ? isOrderCreation
        : isOrderCreation && this.manual_assign_store_list.includes(storeId);

      if (
        isOrderCreation &&
        process.env.VUE_APP_IS_ENABLE_MANUAL_ASSIGN_RIDER === 'true'
      ) {
        this.isEnableManualAssignRider = true;
      } else if (
        reDispatchStatuses.includes(currentStatus) &&
        process.env.VUE_APP_IS_ENABLE_CHANGE_ASSIGN_RIDER === 'true'
      ) {
        this.isEnableManualAssignRider = true;
        this.isEnableChangeAssignRider = true;
      }
    },

    handleReDispatchRider(item) {
      this.isForceShowManualAssign = true;
    },

    handleCheckErrorMessage(error) {
      if (error.status === 404) {
        switch (error.code) {
          case 'EMPLOYEE_NOT_FOUND':
            return `ไม่พบข้อมูลคนขับจากเบอร์นี้ ${this.assign_rider_mobile_no}`;
          case 'EMPLOYEE_NOT_ACTIVE':
            return `ไม่สามารถจ่ายงานให้คนขับเบอร์ ${this.assign_rider_mobile_no} ได้ กรุณากรอกเบอร์คนขับใหม่`;
          default:
            return error.message;
        }
      } else if (error.status === 400) {
        return 'หมายเลขโทรศัพท์ไม่ถูกต้อง';
      } else {
        return error.message;
      }
    },

    initDatetimeWithAutoSet() {
      this.options.itemsPerPage = this.options.itemsPerPage + 1;
      this.getTripsReport(this.options);
    },
  },
  computed: {
    ...mapGetters([
      'canDo',
      'tripsReport',
      'loadingDataTable',
      'lnHookStatus',
      'orderTopupTransactionTypes',
      'isCheckRiderPhoneLoading',
      'isAssignRiderLoading',
      'searchRiderInfo',
      'riderAvailability',
      'assignRiderResponse',
    ]),
    headers() {
      var columns = [
        // { text: '', value: 'data-table-expand' },
        { text: 'Order No.', value: 'order_no', width: '150px' },
        { text: 'Fleet', value: 'fleet_name', width: '80px' },
        { text: 'Trip No.', value: 'trip_name', width: '90px' },
        // {text: "Brand", value: 'brand_alias'},
        {
          text: 'Order Store',
          value: 'store.store_alias_id',
          width: '80px',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Driver Store',
          value: 'employee_store.store_alias_id',
          width: '80px',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Driver Tier',
          value: 'employee_tier_name',
          width: '80px',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Driver',
          value: 'employee_name',
          width: '130px',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Phone No.',
          value: 'employee_phone',
          width: '110px',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Payment Type',
          value: 'payment_method',
          width: '90px',
          align: 'center',
        },
        {
          text: 'Order Status',
          value: 'latest_ln_order_status',
          align: 'center',
          width: '120px',
        },
        // {text: "Gross Amount (Bht)", value: 'gross_amount' , align: 'right'},
        // {text: "Discount Amount (Bht)", value: 'discount_amount' , align: 'right'},
        {
          text: 'Distance (M)',
          value: 'distance',
          align: 'right',
          width: '85px',
        },
        {
          text: 'Total Amount (Bht)',
          value: 'total_amount',
          align: 'right',
          width: '85px',
        },
        {
          text: 'Delivery Fee (Bht)',
          value: 'delivery_fee',
          align: 'right',
          width: '85px',
        },
        // {text: "Driver Delivery Fee (Bht)", value: 'driver_delivery_fee', align: 'right' },
        // {text: "Driver Getpaid Food (Bht)", value: 'driver_getpaid_food', align: 'right' },
        // {text: "Driver Getpaid Delivery (Bht)", value: 'driver_getpaid_delivery', align: 'right' },
        // {text: "Commission (Bht)", value: 'commission' , align: 'right'},
        // {text: "Topup Food (Bht)", value: 'topup_food', align: 'right' },
        // {text: "Topup Fee (Bht)", value: 'topup_delivery', align: 'right' },
        { text: 'Created', value: 'order_created_at' },
        { text: 'Hooked At', value: 'webhook_updated_at' },
        {
          text: '',
          value: 'action',
          sortable: false,
          align: 'center',
          width: '50px',
        },
      ];
      if (this.canDo && this.canDo.can.includes('manual_order_topup')) {
        return columns;
      } else {
        return columns.filter((column) => column.value != 'action');
      }
    },
    dataLisings() {
      var res = this.tripsReport ? this.tripsReport.data : [];
      return res;
    },
    totalResult() {
      return (this.tripsReport ? this.tripsReport.count : null) || 0;
    },
    filter_date_formatted() {
      if (!this.options.filter.date_range) return '';

      var date_format = this.options.filter.date_range.map((x) =>
        moment(x).format('DD-MM-YYYY')
      );
      return typeof date_format == 'object' ? date_format.join(' - ') : '';
    },
    selectedAll() {
      return this.lnHookStatus
        ? this.options.filter.ln_status.length === this.lnHookStatus.length
        : false;
    },
    selectedSome() {
      return this.options.filter.ln_status.length > 0 && !this.selectedAll;
    },
    selectall_icon() {
      if (this.selectedAll) return 'mdi-close-box';
      if (this.selectedSome) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  async beforeMount() {
    await this.prepareStoreData();
    this.tpc_tier_id = await this.getTPCTierId();
  },
  mounted() {
    this.genCan();
    this.getTripsReport(this.options);
    this.getManualAssignStoreList();
    this.user_type = localStorage.getItem('auth_type');
  },
};
</script>
<style>
.v-data-table {
  max-width: 100%;
}
.v-data-table.text-small td {
  font-size: 11px !important;
  height: 40px !important;
  cursor: pointer;
}
.expand-info {
  padding: 10px 20px;
  cursor: default;
}
.expand-info div {
  display: table-row;
}
.expand-info div span {
  display: table-cell;
}
.info-title {
  padding-right: 30px;
}
.info-detail {
  text-align: right;
  vertical-align: middle;
}
.info-detail.success {
  color: #388e3c;
  background: none;
  vertical-align: middle;
}
.custom-table1 {
  border-bottom: 1px solid #ccc;
}
.custom-table1 th {
  background: #eee;
  height: auto;
  border-bottom: none !important;
}
.v-data-table.text-small .custom-table1 td {
  height: auto !important;
  padding: 3px 20px !important;
  border-bottom: 1px solid #ccc;
}

.v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  display: flex;
}
</style>
